<template>
    <form
        id="drop-off-review"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Review
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Review your box
                    </p>
                    <p
                        v-if="form.errors.has('general')"
                        class="
							text-[14px] text-flushing-pink
							font-medium
							w-[250px]
							sm:w-[300px]
							xs:w-[265px]
						"
                    >
                        {{ form.errors.get("general") }}
                    </p>
                    <p
                        v-else
                        class="text-navy text-[14px]"
                    >
                        Make sure these details are correct
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16">
            <BoxDetailsCard
                :box="props.box"
                :is-editable="true"
                :edit-box-tab-index="4"
                :disabled="isLoading"
            />
        </div>

        <BillingDetailsCard
            title="Drop off charge"
            description="Packages over 50 lbs are subject to additional shipping fees"
            :card-last-four="selectedPaymentMethod.card_last_four"
            :is-card-brand-shown="true"
            :is-editable="true"
            :edit-payment-tab-index="3"
            :price="totalPrice"
            :disabled="isLoading"
            :discounted-price="discountedTotalPrice"
            :show-creadit-tag="true"
            :has-bundle="props.box?.bundle?.shipment_credits || false"
        />

        <div class="flex flex-col items-center justify-center px-4">
            <div class="flex w-full h-[65px] mb-2">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-[65px] xs:w-[360px] xs:mx-4"
                    :tabindex="1"
                    data-message="Confirm Button"
                    type="submit"
                >
                    Confirm
                </button>

                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
            <span class="text-center text-navy text-[12px] mb-4 hover:opacity-90">
                By confirming you agree to our
                <a
                    href="https://spacereimagined.io/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    :tabindex="2"
                    data-message="Terms & Conditions Button"
                    class="font-medium"
                >
                    Terms & Conditions
                </a>
            </span>
        </div>

        <Drawer
            :is-shown="isDropOffCompleteDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="false"
            :is-close-shown="true"
            :close-tab-index="2"
            @close="SHOW_DRAWER(null)"
        >
            <DropOffComplete />
        </Drawer>
    </form>
</template>

<script setup>
    import { ref, reactive, onMounted, computed } from 'vue';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';
    import { onBeforeMount } from '@vue/runtime-core';
    import { IS_SIMULATTION } from 'root-js/constants/system.const';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import BillingDetailsCard from 'root-js/components/core/BillingDetailsCard.vue';
    import BoxDetailsCard from 'root-js/components/core/BoxDetailsCard.vue';
    import DropOffComplete from 'root-js/components/your-space/DropOffComplete.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import Form from 'root-js/utils/API/Form';

    const props = defineProps({ box: Object });

    const { storeShipping } = mapGetters('boxes');
    const { selectedPaymentMethod } = mapGetters('payments');
    const { paymentMethods } = mapGetters('payments');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_SELECTED_PAYMENT_METHOD } = mapMutations('payments');
    const { GET_BOXES } = mapActions('boxes');
    const { user } = mapGetters('user');
    const { GET_USER } = mapActions('user');

    const isDropOffCompleteDrawerShown = ref(false);
    const isLoading = ref(false);
    const userBalance = ref(user.value.balance * -1);
    const discountedTotalPrice = ref(null);

    const form = reactive(new Form('create-box-return',
                                   {
                                       id: { value: props.box.id },
                                       method: { value: 'drop_off' },
                                       payment_method: { value: null },
                                       _simulate: { value: IS_SIMULATTION },
                                       _finalize: { value: true }
                                   },
                                   null));

    onBeforeMount(() => {
        const defaultPaymentMethod = paymentMethods.value.find(paymentMethod => paymentMethod.is_default);
        SET_SELECTED_PAYMENT_METHOD(defaultPaymentMethod);
    });

    onMounted(() => {
        if (userBalance.value > 0 && (!props.box.bundle || props.box.bundle && !props.box.bundle.shipment_credits)) {
            calculateDiscountedTotalPrice();
        } else if (props.box.bundle && props.box.bundle.shipment_credits) {
            discountedTotalPrice.value = totalPrice.value;
        }
    });

    async function submit() {
        isLoading.value = true;
        form.payment_method = selectedPaymentMethod.value.id;
        try {
            await form.submit(true);
            GET_BOXES();
            GET_USER();
            isDropOffCompleteDrawerShown.value = true;
        } catch (error) {
            console.log(error);
        }

        isLoading.value = false;
    }


    const totalPrice = computed(() => storeShipping.value.charge + (storeShipping.value.surcharge || 0));


    const calculateDiscountedTotalPrice = () => {
        discountedTotalPrice.value = userBalance.value > totalPrice.value ? totalPrice.value : userBalance.value;
    };
</script>
