import axios from 'axios';
import store from 'root-js/store';

/**
 * Handle all API requests.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class API {

    /**
     * API constructor.
     *
     * @param {string}  url
     * @param {EndpointCollection}  endpoints
     * @param {string|null}  token
     * @param {object}  callbacks
     */
    constructor(url, endpoints, token = null, callbacks = {}) {
        this.endpoints = endpoints;

        // Create local instance of axios
        this.axios = axios.create({
            baseURL: url,
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
        });

        this.callbacks = callbacks;

        // Set the authentication token
        this.setToken(token);

        this._interceptors();
    }

    /**
     * Set Interceptors
     */
    _interceptors() {
        let isRefreshing = false; // Toggle variable to track refresh state

        this.axios.interceptors.response.use(response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        await store.dispatch('auth/LOGOUT');
                        return Promise.reject(new Error('Request is already in progress, logged out.'));
                    }
                    originalRequest._retry = true; // Set _retry to true to prevent further attempts
                    isRefreshing = true; // Set the toggle to true

                    try {
                        const refreshResult = await store.dispatch('auth/REFRESH_TOKEN');
                        if (refreshResult && refreshResult.data) {
                            originalRequest.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
                            return this.axios(originalRequest);
                        }
                        await store.dispatch('auth/LOGOUT');
                        return Promise.reject(new Error('Token refresh failed, user logged out.'));

                    } finally {
                        isRefreshing = false; // Reset the toggle after the refresh attempt
                    }
                } else {
                    console.error(error);
                }

                return Promise.reject(error);
            });
    }

    /**
     * Set the authentication token.
     *
     * @param {string|null}  token
     */
    setToken(token = null) {
        if (token) {
            this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } else {
            delete this.axios.defaults.headers.common.Authorization;
        }
    }

    /**
     * Call an API endpoint by name.
     *
     * @param {string}  name
     * @param {object}  data
     * @param {string|null}  fields
     * @param {object}  options
     * @param {boolean}  ignoreEmpty
     * @returns {Promise}
     */
    call(name, data = {}, fields = null, options = {}, ignoreEmpty = false) {
        const endpoint = this.endpoints.find(name);

        if (!endpoint) {
            throw new Error('Invalid endpoint supplied');
        }

        const request = Object.assign(options,
            this._build(endpoint, data, fields, ignoreEmpty));

        return this._execute(request);
    }

    /**
     * Build the options and configuration for the axios API request.
     *
     * @param {object}  endpoint
     * @param {object}  data
     * @param {string|null}  fields
     * @param {boolean}  ignoreEmpty
     * @returns {object}
     */
    _build(endpoint, data = {}, fields = null, ignoreEmpty = false) {
        const request = {};

        request.method = endpoint.method();
        request.url = endpoint.path(data);
        request.responseType = endpoint.responseType();

        let parameters = {};

        /*
         * Fields define what fields should be returned for each object from the
         * API. If they have been supplied then we need to append them to the
         * request parameters
         */
        if (fields) {
            parameters.fields = fields;
        }

        /*
         * Loop through the data object and remove any empty fields
         */
        if (ignoreEmpty) {
            Object.keys(data).forEach(key => (data[key] === null || data[key] === '') && delete data[key]);
        }

        let formData = false;

        /*
         * If any the of values within data are files then we need to change the
         * request to accept file uploads
         */
        if (!Object.values(data).every(value => !(value instanceof File))) {
            formData = new FormData;

            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });

            request.headers = { 'content-type': 'multipart/form-data' };
        }

        /*
         * Dependent on the type of request, we either attach the data to the
         * request data or merge with the request parameters
         */
        if (['put', 'post', 'patch'].includes(request.method)) {
            request.data = formData instanceof FormData ? formData : data;
        } else {
            parameters = Object.assign(data, parameters);
        }

        request.params = parameters;

        return request;
    }

    /**
     * Execute the specified request.
     *
     * @param {object}  request
     * @returns {Promise}
     */
    _execute(request) {
        return this.axios
            .request(request)
            .catch(error => {
                if (
                    error.response
                    && Object.prototype.hasOwnProperty.call(error.response,
                        'status')
                ) {
                    this._resolveCallback(error.response.status, error);
                }

                throw error;
            })
            .then(response => {
                this._resolveCallback(response.status, response);

                return response;
            });
    }

    /**
     * Resolve the custom callback.
     *
     * @param {int}  code
     * @param {object}  data
     * @returns {void}
     */
    _resolveCallback(code, data = {}) {

        // Run callback for all requests if the callback exists
        if (Object.prototype.hasOwnProperty.call(this.callbacks, 'all')) {
            this.callbacks.all(data);
        }

        // Run specific callback
        if (Object.prototype.hasOwnProperty.call(this.callbacks, code)) {
            this.callbacks[code](data);
        }
    }
}
