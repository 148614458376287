<template>
    <div class="text-navy">
        <div class="mt-8 relative flex">
            <div class="mt-8 mr-10 w-[250px]">
                <h1 class="text-[28px] text-navy tracking-[-0.7px] leading-[1.29] mb-2">
                    Referral Link
                </h1>
                <p class="text-[14px] text-navy">
                    Share your referral link with friends
                </p>
            </div>
            <img
                class="mt-[0.45rem]"
                :src="require('/static/assets/images/misc/clouds-grey.svg')"
                alt="Clouds Grey"
            >
        </div>

        <div class="flex flex-col md:flex-row items-start md:items-center gap-4 mt-8">
            <div class="bg-white rounded-[20px] w-full max-w-[280px] flex items-center shadow-input relative group">
                <input
                    type="text"
                    class="bg-white text-navy text-[14px] py-5 pl-5 w-[80%] focus:outline-none rounded-l-[20px]"
                    :value="referralCode"
                    readonly
                >
                <div
                    class="relative flex items-center px-4 cursor-pointer"
                    @click="copyReferralCode"
                >
                    <img
                        class="w-4 hover:opacity-80 transition-opacity duration-150"
                        :src="require('/static/assets/images/icons/iconcopy_blue.png')"
                        alt="CopyToClipboard"
                    >
                    <Tooltip
                        :text="tooltipText"
                        position="top"
                        class="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    />
                </div>
            </div>

            <div class="relative group w-full max-w-[280px] md:w-auto">
                <button
                    class="btn-primary font-medium text-[12px] h-[40px] px-2 min-w-[100px] flex items-center justify-center w-full md:w-auto"
                    @click="handleShareClick"
                >
                    Share Link
                </button>
                <Tooltip
                    :text="shareTooltipText"
                    position="top"
                    class="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { mapGetters, mapActions } from 'root-js/store/lib.js';
    import Tooltip from 'root-js/components/common/Tooltip.vue';

    const { user } = mapGetters('user');
    const { GET_USER } = mapActions('user');

    const tooltipText = ref('Copy code');
    const shareTooltipText = ref('Share full link');

    // Get just the referral code for display
    const referralCode = computed(() => user.value?.referral_code || '');

    // Generate the full referral link using API_URL from env
    const referralLink = computed(() => {
        if (user.value?.referral_code) {
            return `${process.env.APP_URL}/${user.value.referral_code}`;
        }
        return '';
    });

    onMounted(() => {
        GET_USER();
    });

    async function copyReferralCode() {
        try {
            await navigator.clipboard.writeText(referralCode.value);
            tooltipText.value = 'Code copied!';
            setTimeout(() => {
                tooltipText.value = 'Copy code';
            }, 2000);
        } catch (err) {
            console.error('Failed to copy code: ', err);
            tooltipText.value = 'Failed to copy';
            setTimeout(() => {
                tooltipText.value = 'Copy code';
            }, 2000);
        }
    }

    async function handleShareClick() {
        try {
            await navigator.clipboard.writeText(referralLink.value);
            shareTooltipText.value = 'Full link copied!';

            setTimeout(() => {
                shareTooltipText.value = 'Share full link';
            }, 2000);
        } catch (err) {
            console.error('Failed to share link: ', err);
            shareTooltipText.value = 'Failed to share';

            setTimeout(() => {
                shareTooltipText.value = 'Share full link';
            }, 2000);
        }
    }
</script>
