<template>
    <div class="w-full">
        <form
            autocomplete="off"
            novalidate
            @submit.prevent="submit"
            @keydown="
                form.resetValidation('general');
                form.resetValidation($event.target.name);
            "
            @keyup.enter.exact="submit"
        >
            <h2 class="text-[22px] xs:text-[28px] text-navy mb-2">
                Complete your profile
            </h2>

            <p
                v-if="form.errors.has('general')"
                class="text-[14px] text-flushing-pink font-medium mb-6"
            >
                {{ form.errors.get("general") }}
            </p>

            <p
                v-if="updateForm.errors.has('general')"
                class="text-[14px] text-flushing-pink font-medium mb-6"
            >
                {{ updateForm.errors.get("general") }}
            </p>

            <p
                v-else
                class="text-[14px] text-navy mb-6"
            >
                Enter your details to finish setting up your profile
            </p>

            <div class="xs:flex xs:mb-2 xs:space-x-2">
                <div class="relative">
                    <input
                        id="first_name"
                        ref="firstNameInput"
                        v-model="form.first_name"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        class="
							bg-transparent
							max-w-full
							w-[298px]
							h-[44px]
							rounded-[25px]
							border
							transition
							duration-75
							ease-linear
							px-[22px]
							mb-2
							outline-none
							placeholder:text-light-bite
							focus:border-navy
							disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600
							xs:mb-0 xs:w-[200px] xs:h-[50px] xs:text-[18px]
						"
                        :disabled="isLoading"
                        :class="{
                            'text-navy border-unknown-grey-400 focus:border-navy':
                                !form.first_name && !form.errors.has('first_name'),
                            'text-navy border-navy focus:border-navy':
                                form.first_name && !form.errors.has('first_name'),
                            'border-flushing-pink text-flushing-pink':
                                form.errors.has('first_name')
                        }"
                    >
                </div>

                <input
                    id="last_name"
                    v-model="form.last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    class="
						bg-transparent
						max-w-full
						w-[298px]
						h-[44px]
						rounded-[25px]
						border
						transition
						duration-75
						ease-linear
						outline-none
						px-[22px]
						mb-2
						placeholder:text-light-bite
						focus:border-navy
						disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600
						xs:mb-0 xs:w-[200px] xs:h-[50px] xs:text-[18px]
					"
                    :disabled="isLoading"
                    :class="{
                        'text-navy border-unknown-grey-400 focus:border-navy':
                            !form.last_name && !form.errors.has('last_name'),
                        'text-navy border-navy focus:border-navy':
                            form.last_name && !form.errors.has('last_name'),
                        'border-flushing-pink text-flushing-pink':
                            form.errors.has('last_name')
                    }"
                >
            </div>
            <div class="xs:mb-8 mb-6 relative">
                <input
                    ref="emailInput"
                    v-model="form.email"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    class="
						bg-transparent
						border
						px-[22px]
						max-w-full
						w-[298px]
						h-[44px]
						rounded-[25px]
						outline-none
						transition
						duration-75
						ease-linear
						focus:border-navy
						placeholder:text-light-bite
						disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600
						xs:w-[410px] xs:h-[50px] xs:text-[18px]
					"
                    :disabled="isLoading"
                    :class="{
                        'text-navy border-unknown-grey-400 focus:border-navy':
                            !form.email && !form.errors.has('email'),
                        'text-navy border-navy focus:border-navy':
                            form.email && !form.errors.has('email'),
                        'border-flushing-pink text-flushing-pink': form.errors.has('email')
                    }"
                >

                <template v-if="!user?.value?.id">
                    <div class="mt-4">
                        <label class="text-navy text-[14px] mb-2 block">
                            Have a referral code? Enter here
                        </label>
                        <input
                            v-model="form.referral_code"
                            type="text"
                            name="referral_code"
                            placeholder="Enter referral code"
                            class="
                                bg-transparent
                                border
                                px-[22px]
                                max-w-full
                                w-[298px]
                                h-[44px]
                                rounded-[25px]
                                outline-none
                                transition
                                duration-75
                                ease-linear
                                focus:border-navy
                                placeholder:text-light-bite
                                disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600
                                xs:w-[410px] xs:h-[50px] xs:text-[18px]
                            "
                            :disabled="isLoading"
                            :class="{
                                'text-navy border-unknown-grey-400 focus:border-navy':
                                    !form.referral_code && !form.errors.has('referral_code'),
                                'text-navy border-navy focus:border-navy':
                                    form.referral_code && !form.errors.has('referral_code'),
                                'border-flushing-pink text-flushing-pink': form.errors.has('referral_code')
                            }"
                        >
                    </div>
                </template>

                <div class="flex flex-col pt-2">
                    <span
                        v-if="form.errors.has('email')"
                        class="text-flushing-pink text-[10px] px-5"
                        v-text="form.errors.get('email')"
                    />

                    <span
                        v-if="form.errors.has('first_name')"
                        class="text-flushing-pink text-[10px] px-5"
                        v-text="form.errors.get('first_name')"
                    />
                    <span
                        v-if="form.errors.has('last_name')"
                        class="text-flushing-pink text-[10px] px-5"
                        v-text="form.errors.get('last_name')"
                    />
                    <span
                        v-if="form.errors.has('referral_code')"
                        class="text-flushing-pink text-[10px] px-5"
                        v-text="form.errors.get('referral_code')"
                    />
                </div>
            </div>
            <div class="flex h-[55px] w-[300px] mb-6 xs:w-[270px] xs:h-[65px]">
                <button
                    v-if="!isLoading"
                    ref="finishButton"
                    type="submit"
                    class="btn-primary flex items-center justify-center h-full mb-6"
                    :disabled="isSubmitButtonDisabled"
                >
                    Finish
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </form>

        <div class="flex items-center gap-2">
            <i class="icon icon-help-circle-outline text-navy text-[22px]" />

            <p class="text-navy text-[12px]">
                Want to finish this later?
                <button
                    class="text-flushing-pink font-medium hover:text-bloody-nose"
                    @click="onSkipStep"
                >
                    Skip this step
                </button>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { reactive, ref, computed } from '@vue/reactivity';
    import { onMounted, watch } from '@vue/runtime-core';
    import { mapMutations, mapGetters, mapActions } from 'root-js/store/lib.js';

    import router from 'root-js/routes.js';
    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits([ 'success' ]);

    const { SET_USER } = mapMutations('user');
    const { user, locale } = mapGetters('user');

    const isLoading = ref(false);
    const finishButton = ref(null);
    const firstNameInput = ref(null);
    const emailInput = ref(null);
    const isSignInShown = ref(false);

    const formValidations = {
        first_name: { value: user?.value?.first_name, rule: 'required|min:2' },
        last_name: { value: user?.value?.last_name },
        email: { value: user?.value?.email, rule: 'required|email' },
        referral_code: { value: localStorage.getItem('referralCode') || '' },
        locale: { value: locale, rule: 'required' }
    };

    const form = reactive(new Form('create-user', formValidations, null, { success: () => emits('success') }));

    const updateForm = reactive(new Form('update-user', formValidations, null, { success: () => emits('success') }));

    function onSkipStep() {
        isSignInShown.value = false;
        router.push({ name: 'Your Space' });
    }

    watch(() => form.first_name,
          () => {
              updateForm.first_name = form.first_name;
          });
    watch(() => form.last_name,
          () => {
              updateForm.last_name = form.last_name;
          });
    watch(() => form.email,
          () => {
              updateForm.email = form.email;
          });

    watch(() => locale,
          () => {
              form.locale = locale;
              updateForm.locale = locale;
          },
          { immediate: true });

    onMounted(() => {
        firstNameInput.value.focus();
    });

    const isSubmitButtonDisabled = computed(() => {
        const hasRequiredFields = form.email && form.first_name;
        return !hasRequiredFields;
    });

    async function submit() {
        isLoading.value = true;
        form.resetValidation(['first_name', 'email']);
        try {
            const { data: userDetails } = !user?.value?.id
                ? await form.submit(true) // Use create-user API
                : await updateForm.submit(true); // Use update-user API

            SET_USER(userDetails);
        } catch (error) {
            setTimeout(() => {
                emailInput.value.focus();
            }, 0);
        }
        isLoading.value = false;
    }
</script>
