<template>
    <div
        id="sub-sidebar"
        class="
            bg-white
            text-navy
            w-full
            h-full
            relative
            flex flex-col
            pb-8
            lg:w-[370px]
            pt-16
            md:pt-0
        "
    >
        <router-link
            v-for="menu in menus"
            :key="menu.name"
            v-slot="{ href, navigate, isExactActive }"
            :to="{ name: menu.name }"
            custom
        >
            <a
                :class="{ 'bg-unknown-grey-100 pointer-events-none': isExactActive }"
                class="
                    block
                    relative
                    border-b border-lighter-grey
                    cursor-pointer
                    py-5
                    px-16
                    hover:bg-unknown-grey-100
                "
                :href="href"
                @click="navigate"
            >
                <i
                    class="
                        icon icon-chevron-right
                        text-[14px]
                        absolute
                        top-1/2
                        right-4
                        transform
                        -translate-y-1/2
                    "
                />
                <div class="flex items-center mb-1.5 -ml-8">
                    <i
                        :class="menu.icon"
                        class="text-[24px] mr-4"
                    />
                    <p class="font-medium text-[18px]">{{ menu.name }}</p>
                </div>
                <p class="text-[14px] ml-2">
                    {{ menu.description }}
                </p>
            </a>
        </router-link>
        <div
            class="flex mt-auto items-center justify-center py-10 md:py-8 lg:py-0"
        >
            <button
                class="self-center w-max text-[18px] hover:opacity-80"
                @click="signOut"
            >
                <i class="icon icon-leave text-navy mr-2" />
                Sign out
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from '@vue/runtime-core';
    import { mapActions, mapGetters } from 'root-js/store/lib.js';
    import router from 'root-js/routes.js';

    const { UNSET_AUTH } = mapActions('auth');
    const { user } = mapGetters('user');
    const referrerCredit = ref(50);

    onMounted(async () => {
        try {
            const { data } = await window.api.call('get-user-referrals');
            if (data && typeof data.data.referrer_credit !== 'undefined') {
                referrerCredit.value = data.data.referrer_credit;
            }
        } catch (error) {
            console.error('Error fetching referral credits:', error);
        }
    });

    const menus = computed(() => {
        const baseMenus = [
            {
                name: 'Profile',
                isActive: true,
                icon: 'icon-profile-outline',
                description: 'Make your account your own. Add a profile picture, your name, and email here.'
            },
            {
                name: 'Payment Methods',
                icon: 'icon-dollar-circle-outline',
                isDangerShown: true,
                description: 'Manage your payment methods. Add one or more credit cards to your account here.'
            }
        ];

        // Only add invite friends menu if user exists and has an id
        if (user.value && user.value.id) {
            baseMenus.push({
                name: 'Invite Friends',
                icon: 'icon-profile-outline',
                description: `Share your referral code to your friends and get $${referrerCredit.value} credits when each of them order their first box.`
            });
        }

        baseMenus.push({
            name: 'Deactivate Account',
            icon: 'icon-trash',
            description: 'Deactivate your account here.',
            requiresUser: true
        });

        return baseMenus;
    });

    async function signOut() {
        await UNSET_AUTH();
        router.push({ name: 'Welcome' });
    }
</script>
