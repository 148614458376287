<template>
    <div class="bg-white h-[80px] w-full shadow-thin">
        <div class="flex justify-between items-center w-full h-full px-8">
            <!-- Left section -->
            <div class="flex items-center">
                <h1 class="text-[28px] text-navy hidden md:block">
                    {{ $route.matched[0].name }}
                </h1>

                <img
                    class="w-[104.4px] h-[32px] block md:hidden"
                    :src="require('static/assets/images/brand/logo-blue.svg')"
                    alt="Space Logo"
                >
            </div>

            <!-- Right section -->
            <div class="flex items-center space-x-6">
                <router-link
                    v-if="shouldShowReferButton"
                    to="/settings/referral"
                    class="btn-primary font-medium text-[12px] h-[40px] px-4 min-w-[100px] hidden md:flex items-center justify-center"
                >
                    Refer a Friend
                </router-link>

                <UserDropdown class="hidden md:block" />

                <div class="flex items-center space-x-4">
                    <div
                        class="h-[25px] w-[25px] rounded-full text-white bg-flushing-pink flex items-center justify-center cursor-pointer hover:bg-bloody-nose"
                        @click="SHOW_DRAWER(DRAWERS.HELP_CENTER)"
                    >
                        ?
                    </div>
                    <span class="h-[21px] w-[1px] bg-lighter-grey" />
                    <CartNotification @click="onClickAddToCart" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { onMounted } from '@vue/runtime-core';
    import { mapMutations, mapGetters, mapActions } from 'root-js/store/lib.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import CartNotification from 'root-js/components/core/CartNotification.vue';
    import UserDropdown from 'root-js/components/core/UserDropdown.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import ProductList from 'root-js/components/your-space/ProductList.vue';
    import YourCart from 'root-js/components/your-space/YourCart.vue';

    const { cartBoxes } = mapGetters('store');
    const { user } = mapGetters('user');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { products } = mapGetters('store');
    const { GET_PRODUCTS } = mapActions('store');
    const { GET_USER } = mapActions('user');

    const isProductListDrawerShown = ref(false);
    const isYourCartDrawerShown = ref(false);

    const shouldShowReferButton = computed(() => {
        const hasUser = Boolean(user.value);
        const hasId = Boolean(user.value?.id);
        return hasUser && hasId;
    });

    onMounted(async () => {

        // Only fetch user if it's undefined or has _required field (not initialized)
        if (!user.value || user.value._required) {
            await GET_USER();
        }
    });

    async function onClickAddToCart() {
        if (!cartBoxes.value.filter(cartBox => cartBox.quantity).length) {
            await GET_PRODUCTS();
            products.value.length > 1
                ? SHOW_DRAWER(DRAWERS.PRODUCT_LIST)
                : SHOW_DRAWER(DRAWERS.PRODUCT_DETAIL);
            return;
        }
        SHOW_DRAWER(DRAWERS.PRODUCT_CART);
    }
</script>
